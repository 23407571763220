import React from 'react'
import { graphql, Link } from 'gatsby'

import WorkContainer from '../../components/WorkContainer'
import Layout from '../../components/Layout'
import { ProjectTitle, EnglishOnly } from '../../components/Text'
import Metadata from '../../components/Metadata'
import CustomerLogo from '../../components/CustomerLogo'
import ProjectImage from '../../components/ProjectImage'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export default function NegotiateChange({ data }) {
  const { t } = useTranslation()

  const project = data.projectsJson

  return (
    <Layout>
      <Metadata title={t(project.title)} isArticle />
      <WorkContainer>
        <CustomerLogo project={project} />
        <ProjectTitle title={t(project.title)} />
        <EnglishOnly />
        <ProjectImage data={project.gallery.main} top />
        <h2>Need</h2>
        <p>
          A real-world laboratory for continuously optimizing sustainability
          policy. Need Efforts to improve sustainability are currently pursued
          in an unsystematic way. There is no clear criteria for choosing which
          sustainability initiatives to prioritize based on factors like
          feasibility, importance, or impact. Sometimes, efforts are chosen or
          ignored without good reasons. There is a lack of alignment between
          aspirational, scientific, and political goals. This lack of direction
          creates uncertainty and disempowers people, making them less motivated
          to contribute to sustainability improvements, which can harm these
          efforts. Approach In cognitive neuroscience, Bayesian statistical
          models help us understand how people make decisions when faced with
          uncertainty. We present the "Reallab" framework, which is a systematic
          approach for generating, choosing, implementing, and monitoring
          sustainability efforts. The "Reallab" framework makes it possible for
          participants to express their intuitive Bayesian thought process in a
          clear and measurable way. This helps participants reach a consensus on
          goals and their inner beliefs on the success of sustainability
          efforts. Furthermore, it enables participants to monitor ongoing
          initiatives and make adjustments based on new information as needed.
          Benefit The Reallab consolidates sustainability efforts under a
          unified approach to reasoning and decision-making. It can be applied
          to small and large efforts of any type. It provides a shared
          understanding of intervention impact and success likelihood at any
          time in the process It aligns aspirational, scientific, and political
          sustainability goals. It offers predictability to those planning
          sustainability efforts. It provides guidance to those financing these
          efforts. It supplies evidence to decision-makers involved in
          sustainability initiatives. Finally, it acts as a valuable repository
          of interventions with their outcome to inform strategy.
        </p>
        <p>
          If you collaborate on documents, change highlighting using{' '}
          <strong>Track Changes</strong> or Google Docs <strong>Suggest</strong>{' '}
          is likely your key tool.
        </p>
        <p>
          Also, you likely suffer its downsides: It can be very challenging to
          understand edits made with "Track Changes", making it insufficient as
          a tool to negotiate agreement among multiple variants:
          <ul>
            <li>
              "Track Changes" are virtually glued to the shared document.
              Multiple alternative versions of a section can hardly co-exist,
              making it challenging to negotiate, reach agreement, or find
              compromise.
            </li>
            <li>
              Meaningful changes, such as editing and moving a section, can be
              very hard to grasp.
            </li>
            <li>
              When working together online, there is no support for tentative or
              private proposals - every suggestion is immediately visible to
              everyone.
            </li>
          </ul>
          You might encounter additional difficulties around document versioning
          and permissions.
        </p>
        <blockquote>Collaboration is the ability to negotiate</blockquote>
        <p>
          As a result, contributors experience discomfort in overwriting others'
          content. This stifles creativity and obstructs the desired content
          negotiation process.
        </p>
        <p>
          For real collaboration to happen, we need an ability to fully
          negotiate document content. "Track Changes" does not foster that
          collaboration.
        </p>
        <h2>Approach</h2>
        <p>
          We need an approach where we can first formulate content without
          restriction, and only in a second step agree on what to include in the
          shared document version, and how to achieve compromise.
        </p>
        <p>
          We have developed a web application to improve collaboration through
          three key enhancements. For the tech-savvy: there are similarities
          with the <i>git</i> approach:
          <ul>
            <li>
              First, it ensures contributors do not overwrite each other by
              automatically assigning a <strong>document copy</strong> to every
              contributor. This way, even heavily redacted sections can easily
              receive new proposals.
            </li>
            <li>
              Second, it makes edits understandable by{' '}
              <strong>calculating actual change</strong> instead of simply{' '}
              <strong>tracking edit history</strong> as "Track Changes" does.
            </li>
            <li>
              Last, edits stay private until contributors consider them ready
              for review. When contributors share their work, this creates a{' '}
              <strong>document version</strong>.
            </li>
          </ul>
        </p>
        <blockquote>
          <strike>Track</strike>
          <u>Understand</u> changes
        </blockquote>
        <p>
          To improve understanding of changes, we have developed a custom
          algorithm that presents modifications made by any contributor as
          intuitive, atomic changes. These can be individually accepted,
          rejected, or modified, facilitating negotiation and reaching
          agreement.
        </p>
        <p>
          The algorithm is described on our{' '}
          <Link to="../treediff">Tree diff page</Link>.
        </p>
        <p>
          The resulting "understandable changes" look very similar to "Track
          Changes", we only learn one new hint that "Track Changes" cannot
          produce: greyed-out sections indicate moved-away sections.
        </p>
        <p>Lets look at an example:</p>
        <ProjectImage data={project.gallery.trackUnderstand} />
        <p>
          <i>
            <small>
              Our application captures both sentence movement and word
              replacement, which both "Track Change" and "Suggest" fail to
              recognize.
            </small>
          </i>
        </p>
        <p>
          Our algorithm detects two changes: A sentence move, and a word
          replacement. Both can be separately accepted or rejected.
        </p>
        <p>
          In comparison, both "Track Changes" and "Suggest" offer only editing
          history: A sentence removed, a sentence added, with no indication that
          it is in fact the same. Spotting this manually becomes exceedingly
          difficult on meaningful changes.
        </p>
        <h2>Benefits</h2>
        <p>There are important benefits to this collaboration approach:</p>
        <p>
          <ul>
            <li>
              Understand changes of any complexity and by multiple contributors
            </li>
            <li>
              Freely and concurrently edit the document, without having to
              navigate around other contributors' redactions
            </li>
            <li>
              Negotiating change and finding compromise become a seamless part
              of the editing experience
            </li>
            <li>
              Increase the number of contributors without losing any clarity on
              competing changes
            </li>
            <li>Simplify document permissions management</li>
            <li>Fully automatic versioning and audit trail</li>
            <li>New workflows such as requesting private edits</li>
          </ul>
        </p>
        <h2>Current state</h2>
        <p>
          An early version of the web application is currently being evaluated
          by a small number of businesses.
        </p>
      </WorkContainer>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    projectsJson(name: { eq: "negotiateChange" }) {
      name
      title
      customer
      customerLogo {
        childImageSharp {
          gatsbyImageData
        }
      }
      tags
      link
      gallery {
        main {
          alt
          src {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        trackUnderstand {
          alt
          src {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        ideacomp_feedback {
          alt
          src {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
